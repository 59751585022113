@import './theme.css';
@import url('https://fonts.googleapis.com/css2?family=Freckle+Face&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bahianita&display=swap');

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../node_modules/material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(../node_modules/material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
    url(../node_modules/material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
    url(../node_modules/material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/* latin-ext */
@font-face {
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  src: local('Righteous'), local('Righteous-Regular'), url(./Fonts/Righteous-Regular.ttf) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  src: local('Righteous'), local('Righteous-Regular'), url(./Fonts/Righteous-Regular.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 400;
  src: local('Karla Italic'), local('Karla-Italic'), url(./Fonts/Karla-Italic.ttf) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 400;
  src: local('Karla Italic'), local('Karla-Italic'), url(./Fonts/Karla-Italic.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 700;
  src: local('Karla Bold Italic'), local('Karla-BoldItalic'), url(./Fonts/Karla-BoldItalic.ttf) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 700;
  src: local('Karla Bold Italic'), local('Karla-BoldItalic'), url(./Fonts/Karla-BoldItalic.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: local('Karla'), local('Karla-Regular'), url(./Fonts/Karla-Regular.ttf) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: local('Karla'), local('Karla-Regular'), url(./Fonts/Karla-Regular.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  src: local('Karla Bold'), local('Karla-Bold'), url(./Fonts/Karla-Bold.ttf) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  src: local('Karla Bold'), local('Karla-Bold'), url(./Fonts/Karla-Bold.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  background: var(--main-background);
  min-height: 100%;
  font-size: 16px;
  color: #e4e7ea;
}
html, button {
  font-family: Karla, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}

#appVersion {
  position: fixed;
  right: 5px;
  bottom: 3px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
}

a {
  color: #5ba3e2;
  text-decoration: none;
}

pre {
  user-select: text;
}

@keyframes focused-element {
  0% { outline-color: rgba(255, 255, 255, 0); }
  100% { outline-color: rgba(255, 255, 255, 0.5); }
}

*:focus {
  outline: 1px solid rgba(0, 0, 0, 0);
  animation-name: focused-element;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

input[type="number"], input[type="text"], textarea {
  background: none;
  border: none;
  border-bottom: rgba(255, 255, 255, .2) 2px solid;
  box-sizing: border-box;
  color: #bcc9e2;
  font-family: Karla, sans-serif;
  font-size: 16px;
}
input[type="number"], input[type="text"] {
  height: 21px;
  margin: 2px 0;
}
textarea {
  border: rgba(255, 255, 255, .2) 2px solid;
}

@keyframes focused-input-invalid {
  0% { border-color: rgba(255, 0, 0, 0.1); }
  100% { border-color: rgba(255, 0, 0, 0.3); }
}
input[type="number"]:focus.InvalidInput, input[type="text"]:focus.InvalidInput, input:focus.InvalidInput {
  animation-name: focused-input-invalid;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}
@keyframes focused-input {
  0% { border-color: rgba(255, 255, 255, 0.1); }
  100% { border-color: rgba(255, 255, 255, 0.3); }
}
input[type="number"]:focus, input[type="text"]:focus, input:focus {
  animation-name: focused-input;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

h1, h2, h3, h4, .Header1, .Header2, .Header3 .Header4,
input[type="text"].Header1, input[type="text"].Header2, input[type="text"].Header3, input[type="text"].Header4 {
  color: #ffffff;
}

h1, .Header1, input[type="text"].Header1 {
  font-size: 60px;
  font-family: 'Bahianita', cursive;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: normal;
}

h2, .Header2, input[type="text"].Header2 {
  font-size: 50px;
  font-family: 'Bahianita', cursive;
  margin-top: 30px;
  margin-bottom: 5px;
  font-weight: normal;
}

h3, .Header3, input[type="text"].Header3 {
  font-size: 30px;
  font-family: 'Bahianita', cursive;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: normal;
}

small {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}
h1 small, h2 small, h3 small {
  color: rgb(200, 200, 200);
}
h1 a, h2 a, h3 a, h4 a {
  text-decoration: none;
}

.firebaseui-subtitle, .firebaseui-title {
  color: var(--h3-color);
  text-align: center;
}

.Dimmed {
  opacity: 0.5;
}

.CodeMirror {
  font-size: 12px;
}

.OneLineEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
}

th {
  text-align: left;
}
